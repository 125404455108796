<template>
  <el-dialog
    v-model="showJournalBusinessEventDialog"
    width="30%"
    align-center
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    class="elv-journal-business-event-dialog"
    @close="onCloseDialog"
  >
    <template #header="{ titleId }">
      <h4 :id="titleId" class="elv-journal-business-event-dialog-header__title">
        {{ t('common.businessEvent') }}
      </h4>
    </template>
    <div
      v-loading="dialogLoading"
      class="elv-journal-business-event-content"
      :class="{ 'is-center': !businessEventList.length }"
    >
      <ul v-if="businessEventList.length" class="elv-journal-business-event-list">
        <li v-for="item in businessEventList" :key="item.journalGroupConfigId" class="elv-journal-business-event-item">
          <div class="elv-journal-business-event-item__info">
            <p>{{ item.name }}</p>
            <span
              >{{ item.conditionJournalTypeIds.length }}
              {{ item.conditionJournalTypeIds.length > 1 ? t('report.journals') : t('report.Journal') }}</span
            >
          </div>
          <div class="elv-journal-business-event-item__operating">
            <SvgIcon name="sources-edit" width="16" height="16" @click="onEditConfigureBusinessEvent(item)" />
            <SvgIcon name="sources-delete" width="16" height="16" @click="onOpenDeleteConfirm(item)" />
          </div>
        </li>
      </ul>

      <div class="elv-journal-business-event-add-button">
        <elv-button height="32" width="160" plain type="primary" @click="onAddConfigureBusinessEvent"
          ><SvgIcon name="add-default" width="16" height="16" />{{ t('button.addNewEvent') }}</elv-button
        >
      </div>
    </div>
  </el-dialog>

  <ElvMessageBox
    ref="deleteMessageBoxRef"
    :showCancelButton="true"
    :confirmButtonText="t('button.delete')"
    :cancelButtonText="t('button.cancel')"
    :title="t('title.deleteBusinessEvent')"
    :loading="deleteLoading"
    @onConfirmEvent="onConfirmDeleteEvent"
    @onCancelEvent="onCancelBusinessEvent"
  >
    <template #content>
      <span class="elv-confirm-info-header__title">{{
        t('message.deleteBusinessEventInfo', { event: currentBusinessEvent?.name })
      }}</span></template
    >
  </ElvMessageBox>

  <ConfigureBusinessEventDialog
    ref="configureBusinessEventDialogRef"
    :currentData="currentBusinessEvent ?? {}"
    :mode="configureBusinessEventMode"
    @onResetList="onResetList"
  />
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
// import { cloneDeep } from 'lodash-es'
import { ElMessage } from 'element-plus'
import TransactionsApi from '@/api/TransactionsApi'
import { useEntityStore } from '@/stores/modules/entity'
import { JournalGroupBusinessEventType } from '#/TransactionsTypes'
import { useTransactionStore } from '@/stores/modules/transactions'
import ConfigureBusinessEventDialog from './ConfigureBusinessEventDialog.vue'

// eslint-disable-next-line no-unused-vars
const props = defineProps({
  currentData: {
    type: Object,
    default: () => {
      return {}
    }
  }
})

const route = useRoute()
const { t } = useI18n()
// eslint-disable-next-line no-unused-vars
const emit = defineEmits(['onResetList'])
const entityStore = useEntityStore()
const transactionStore = useTransactionStore()
const { businessEventList } = storeToRefs(transactionStore)

const deleteMessageBoxRef = useTemplateRef('deleteMessageBoxRef')
const configureBusinessEventDialogRef = useTemplateRef('configureBusinessEventDialogRef')
const showJournalBusinessEventDialog = ref(false)
const configureBusinessEventMode = ref('add')
const currentBusinessEvent = ref<JournalGroupBusinessEventType | null>(null)
const deleteLoading = ref(false)
const dialogLoading = ref(false)

const entityId = computed(() => {
  return String(route.params?.entityId as string)
})

const currentEntityPermission = computed(() => {
  return entityStore.entityPermission()
})

const onCheckJournalBusinessEventDialog = () => {
  showJournalBusinessEventDialog.value = !showJournalBusinessEventDialog.value
}

const onCloseDialog = () => {}

const onAddConfigureBusinessEvent = () => {
  if (['MEMBER', ''].includes(currentEntityPermission.value?.role)) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  currentBusinessEvent.value = null
  configureBusinessEventMode.value = 'add'
  configureBusinessEventDialogRef.value?.onCheckJournalConfigureBusinessEventDialog()
}

const onEditConfigureBusinessEvent = (val: JournalGroupBusinessEventType) => {
  if (['MEMBER', ''].includes(currentEntityPermission.value?.role)) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  configureBusinessEventMode.value = 'edit'
  currentBusinessEvent.value = val
  configureBusinessEventDialogRef.value?.onCheckJournalConfigureBusinessEventDialog()
}

const onOpenDeleteConfirm = (val: JournalGroupBusinessEventType) => {
  if (['MEMBER', ''].includes(currentEntityPermission.value?.role)) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  currentBusinessEvent.value = val
  deleteMessageBoxRef.value?.onCheckMessageBoxDialog()
}

const getJournalGroupBusinessEventList = async () => {
  try {
    dialogLoading.value = true
    await transactionStore.fetchJournalGroupBusinessEventList(entityId.value)
  } catch (error) {
    console.error(error)
  } finally {
    dialogLoading.value = false
  }
}

const onResetList = () => {
  getJournalGroupBusinessEventList()
  if (transactionStore.journalGroupList.total > 0) {
    emit('onResetList')
  }
}

const onCancelBusinessEvent = () => {
  deleteMessageBoxRef.value?.onCheckMessageBoxDialog()
}

const onConfirmDeleteEvent = async () => {
  try {
    deleteLoading.value = true
    await TransactionsApi.deleteJournalGroupBusinessEvent(
      entityId.value,
      currentBusinessEvent.value?.journalGroupConfigId ?? ''
    )
    deleteMessageBoxRef.value?.onCheckMessageBoxDialog()
    ElMessage.success(t('message.deleteSuccess'))
    getJournalGroupBusinessEventList()
    if (transactionStore.journalGroupList.total > 0) {
      emit('onResetList')
    }
  } catch (error: any) {
    console.log(error)
    ElMessage.error(error.message)
  } finally {
    deleteLoading.value = false
  }
}

defineExpose({ onCheckJournalBusinessEventDialog })

watch(
  () => showJournalBusinessEventDialog.value,
  async () => {
    if (showJournalBusinessEventDialog.value) {
      getJournalGroupBusinessEventList()
    }
  },
  {
    immediate: true
  }
)
</script>

<style lang="scss">
.elv-journal-business-event-dialog {
  width: 500px;
  box-shadow:
    0px 2px 6px rgba(0, 0, 0, 0.05),
    0px 0px 1px rgba(0, 0, 0, 0.3);
  border-radius: 6px;

  .el-dialog__header {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    height: 54px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 700;
    margin: 0;
    padding: 0;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    color: #0e0f11;
    padding-left: 24px;
    border-bottom: 1px solid #edf0f3;

    .elv-journal-business-event-dialog-header__title {
      font-family: 'Plus Jakarta Sans';
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #0e0f11;
      margin: 0;
    }
  }

  .el-dialog__body {
    padding: 18px 25px 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .elv-journal-business-event-content {
      width: 100%;
      min-height: 218px;

      &.is-center {
        display: flex;
        align-items: center;
        justify-content: center;
        transform: translateY(-12%);
      }
    }

    .elv-journal-business-event-list {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      margin-bottom: 16px;

      .elv-journal-business-event-item {
        width: 100%;
        height: 51px;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        padding: 8px 12px;
        background: #f9fafb;
        justify-content: space-between;
        border-radius: 5px;
      }

      .elv-journal-business-event-item__info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        font-family: 'Plus Jakarta Sans';

        p {
          font-size: 13px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          color: #000;
          margin-bottom: 4px;
        }

        span {
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          color: #838d95;
          text-transform: lowercase;
        }
      }

      .elv-journal-business-event-item__operating {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;

        svg {
          fill: #838d95;
          cursor: pointer;

          &:hover {
            fill: #1753eb;
          }
        }
      }
    }

    .elv-journal-business-event-add-button {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
