<template>
  <section v-loading.lock="props.tableLoading" class="elv-journals-group-container">
    <el-scrollbar class="elv-journals-group-scrollbar">
      <template v-if="transactionStore.journalGroupList?.list?.length">
        <Collapse
          v-for="(item, index) in transactionStore.journalGroupList?.list"
          :key="index"
          v-model:activeKey="activeKey"
          class="elv-journals-group-collapse"
          expand-icon-position="end"
          collapsible="icon"
        >
          <template #expandIcon="scope">
            <SvgIcon
              name="arrow-down-line"
              width="14"
              height="14"
              :style="{ transform: scope?.isActive ? 'rotate(180deg)' : 'rotate(0deg)' }"
            />
          </template>
          <CollapsePanel :key="index" class="elv-journals-group-collapse-item">
            <template #header>
              <div class="elv-journals-group-collapse-item__header">
                <div class="elv-journals-group-collapse-item__header-title">
                  <h5>{{ item?.journalGroupConfig?.name ?? '(Deleted event)' }}</h5>
                  <div>
                    <el-tooltip
                      :show-arrow="false"
                      effect="dark"
                      placement="top"
                      popper-class="elv-report-table-tips"
                      overlay-classname="elv-report-table-tips"
                      :show-after="500"
                    >
                      <p>
                        {{
                          dayjs(item.datetime)
                            .tz(entityStore.entityDetail?.timezone ?? 'UTC')
                            .format('YYYY/MM/DD HH:mm')
                        }}
                      </p>
                      <template #content>
                        <div>
                          <p>
                            {{ t('project.originalDatetime') }}{{ calculateUtcOffset(item.datetime, 'UTC', true) }}:
                            {{ dayjs(item.datetime).tz('UTC').format('YYYY/MM/DD HH:mm') }}
                          </p>
                          <p>
                            {{ t('project.utcDatetime') }}:
                            {{ dayjs.tz(item.datetime, 'UTC').format('YYYY/MM/DD HH:mm') }}
                          </p>
                          <p>
                            {{ t('project.reportingDatetime')
                            }}{{ calculateUtcOffset(item.datetime, entityStore.entityDetail?.timezone ?? 'UTC') }}:
                            {{
                              dayjs(item.datetime)
                                .tz(entityStore.entityDetail?.timezone ?? 'UTC')
                                .format('YYYY/MM/DD HH:mm')
                            }}
                          </p>
                        </div>
                      </template>
                    </el-tooltip>
                    <span>{{ item.referenceNo }}</span>
                  </div>
                </div>
                <div class="elv-journals-group-collapse-item__header-amount">
                  <div v-if="item.inflowAmountFC" class="elv-journals-group-collapse-item__header-amount-item">
                    <SvgIcon name="inflow-arrow" width="14" height="14" />
                    <div class="elv-journals-group-collapse-item__header-amount__usd">
                      <span
                        :title="`${fieldValueFormat(
                          item.inflowAmountFC,
                          {
                            price: true,
                            keepPoint: true,
                            symbol: `${
                              entityDetail?.underlyingCurrency?.sign ||
                              `${entityDetail?.underlyingCurrency?.showSymbol} `
                            }`
                          },
                          'NUMBER'
                        )}`"
                        >{{
                          formatNumberToSignificantDigits(
                            item.inflowAmountFC,
                            2,
                            `${
                              entityDetail?.underlyingCurrency?.sign ||
                              `${entityDetail?.underlyingCurrency?.showSymbol} `
                            }`
                          )
                        }}</span
                      >
                      <div
                        v-if="item.inflowValueList.length === 1"
                        class="elv-journals-group-collapse-item__header-amount__currency-value"
                      >
                        <img
                          :onerror="useDefaultImage"
                          :src="currentIcon(item.inflowValueList[0]?.currency)?.logo ?? defaultImg"
                          :alt="item.inflowValueList[0]?.currency"
                        />
                        <p :title="`${item.inflowValueList[0]?.amount} ${item.inflowValueList[0]?.currency}`">
                          {{ formatNumberToSignificantDigits(item.inflowValueList[0]?.amount, 2, '', false) }}
                          {{ item.inflowValueList[0]?.currency }}
                        </p>
                      </div>
                      <div
                        v-if="item.inflowValueList.length > 1"
                        class="elv-journals-group-collapse-item__header-amount__currency-list"
                      >
                        <img
                          v-for="(inflowValue, i) in item.inflowValueList.slice(0, 2)"
                          :key="i"
                          :onerror="useDefaultImage"
                          :src="currentIcon(inflowValue?.currency)?.logo ?? defaultImg"
                          :alt="inflowValue?.currency"
                        />
                        <div v-if="item.inflowValueList.length > 2">+{{ item.inflowValueList.length - 2 }}</div>
                      </div>
                    </div>
                  </div>
                  <div v-if="item.outflowAmountFC" class="elv-journals-group-collapse-item__header-amount-item">
                    <SvgIcon name="outflow-arrow" width="14" height="14" />
                    <div class="elv-journals-group-collapse-item__header-amount__usd">
                      <span
                        :title="`${fieldValueFormat(
                          item.outflowAmountFC,
                          {
                            price: true,
                            keepPoint: true,
                            symbol: `${
                              entityDetail?.underlyingCurrency?.sign ||
                              `${entityDetail?.underlyingCurrency?.showSymbol} `
                            }`
                          },
                          'NUMBER'
                        )}`"
                        >{{
                          formatNumberToSignificantDigits(
                            item.outflowAmountFC,
                            2,
                            `${
                              entityDetail?.underlyingCurrency?.sign ||
                              `${entityDetail?.underlyingCurrency?.showSymbol} `
                            }`
                          )
                        }}</span
                      >
                      <div
                        v-if="item.outflowValueList.length === 1"
                        class="elv-journals-group-collapse-item__header-amount__currency-value"
                      >
                        <img
                          :onerror="useDefaultImage"
                          :src="currentIcon(item.outflowValueList[0]?.currency)?.logo ?? defaultImg"
                          :alt="item.outflowValueList[0]?.currency"
                        />
                        <p :title="`${item.outflowValueList[0]?.amount} ${item.outflowValueList[0]?.currency}`">
                          {{ formatNumberToSignificantDigits(item.outflowValueList[0]?.amount, 2, '', false) }}
                          {{ item.outflowValueList[0]?.currency }}
                        </p>
                      </div>
                      <div
                        v-if="item.outflowValueList.length > 1"
                        class="elv-journals-group-collapse-item__header-amount__currency-list"
                      >
                        <img
                          v-for="(outflowValue, i) in item.outflowValueList.slice(0, 2)"
                          :key="i"
                          :onerror="useDefaultImage"
                          :src="currentIcon(outflowValue?.currency)?.logo ?? defaultImg"
                          :alt="outflowValue?.currency"
                        />
                        <div v-if="item.outflowValueList.length > 2">+{{ item.outflowValueList.length - 2 }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="false" class="elv-journals-group-collapse-item__info">
                <div class="elv-journals-group-collapse-item__info-list">
                  <template v-if="item?.journalGroupConfig?.additionalItems">
                    <div v-for="(additional, i) in item?.journalGroupConfig?.additionalItems" :key="i">
                      <p
                        v-if="
                          (additional?.label !== 'Broker fee' && additional?.label !== 'Pair') ||
                          (additional?.label === 'Broker fee' && item.additionalItemsData[i] !== 0) ||
                          (additional?.label === 'Pair' && item.additionalItemsData[i] !== 0)
                        "
                      >
                        {{ additional.label
                        }}<span>{{
                          additional?.type === 'DISPOSAL_INVENTORY_LOTS' || additional?.label === 'Pair'
                            ? item.additionalItemsData[i]
                            : fieldValueFormat(
                                item.additionalItemsData[i],
                                {
                                  price: true,
                                  symbol:
                                    entityDetail?.underlyingCurrency?.sign ||
                                    entityDetail?.underlyingCurrency?.showSymbol
                                },
                                'NUMBER'
                              )
                        }}</span>
                      </p>
                    </div>
                  </template>
                </div>
                <div class="elv-journals-group-collapse-item__info-total">
                  <span>{{ item.journalActivityList?.length }}</span
                  >{{ t('report.journals') }}
                </div>
              </div>
            </template>
            <JournalGroupTable
              v-if="item.journalActivityList.length"
              :tableData="item.journalActivityList"
              @openDetailOverlay="openDetailOverlay"
            />
          </CollapsePanel>
        </Collapse>
      </template>

      <EmptyResult v-else :img-src="noDataImg" width="64px" height="64px" class="elv-journals-group-empty-content">
        <template #content>
          <p v-if="!transactionStore.businessEventList.length">
            {{ t('message.businessEventsEmpty') }}<span @click="onSetBusinessEventNow">{{ t('button.setupNow') }}</span>
          </p>
          <p v-else-if="filterTotal && transactionStore.businessEventList.length">{{ t('common.noData') }}</p>
          <p v-else>
            {{ t('message.businessEventsEmptyRegenerate')
            }}<span
              :class="{ 'is-disabled': entityStore.entityDetail?.progress.journalStatus === 'JOURNAL_GROUP' }"
              @click="onRegenerateJournalGroup"
              >{{ t('button.clickToGenerate') }}</span
            >
          </p>
        </template>
      </EmptyResult>
    </el-scrollbar>
    <elv-pagination
      class="elv-journals-list-pagination"
      size-popper-class="elv-journals-pagination__sizes-popper"
      :limit="props.journalGroupsParams.limit"
      :current-page="props.journalGroupsParams.page"
      :total="transactionStore.journalGroupList.total"
      @current-change="onChangePage"
      @size-change="onChangePageSize"
    />
  </section>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import { find } from 'lodash-es'
import utc from 'dayjs/plugin/utc'
import { useI18n } from 'vue-i18n'
import defaultImg from '@/assets/img/default-logo.png'
import JournalGroupTable from './JournalGroupTable.vue'
import { useEntityStore } from '@/stores/modules/entity'
import { Collapse, CollapsePanel } from 'ant-design-vue'
import EmptyResult from '@/components/Base/EmptyResult.vue'
import noDataImg from '@/assets/img/reports/table-built.png'
import { useTransactionStore } from '@/stores/modules/transactions'
import { fieldValueFormat, calculateUtcOffset, formatNumberToSignificantDigits } from '@/lib/utils'

const props = defineProps({
  tableLoading: {
    type: Boolean,
    default: false
  },
  journalGroupsParams: {
    type: Object,
    required: true
  },
  listValue: {
    type: String,
    default: 'EXPAND'
  }
})

dayjs.extend(utc)
// eslint-disable-next-line no-unused-vars
const { t } = useI18n()
const route = useRoute()
const entityStore = useEntityStore()
const { entityDetail } = storeToRefs(entityStore)
const transactionStore = useTransactionStore()
const emit = defineEmits([
  'onChangePage',
  'onChangePageSize',
  'openDetailOverlay',
  'onOpenBusinessEventDialog',
  'onRegenerateJournalGroup'
])

const activeKey: any = ref([])

const entityId = computed(() => {
  return String(route?.params?.entityId)
})

const currentIcon: any = computed(() => {
  return (currency: string) => {
    return find(entityStore.currencyList, { symbol: currency })
  }
})

const filterTotal = computed(() => {
  return (
    transactionStore.entityJournalGroupFilterList.find((item: any) => {
      return item.entityId === entityId.value
    })?.total ?? 0
  )
})

const useDefaultImage = (event: any) => {
  event.target.src = defaultImg
}

const onChangePage = (page: number) => {
  emit('onChangePage', page)
}

const onChangePageSize = (limit: number) => {
  emit('onChangePageSize', limit)
}

const onCheckTableExpand = (status: string) => {
  if (status === 'EXPAND') {
    activeKey.value = Array.from({ length: props.journalGroupsParams.limit }, (v, k) => k)
  } else {
    activeKey.value = []
  }
}

const openDetailOverlay = (data: any) => {
  emit('openDetailOverlay', data)
}

const onSetBusinessEventNow = () => {
  emit('onOpenBusinessEventDialog')
}

const onRegenerateJournalGroup = () => {
  emit('onRegenerateJournalGroup')
}

defineExpose({
  onCheckTableExpand
})

watch(
  [() => props.listValue, () => props.journalGroupsParams.limit],
  () => {
    if (props.listValue === 'EXPAND') {
      activeKey.value = Array.from({ length: props.journalGroupsParams.limit }, (v, k) => k)
    } else {
      activeKey.value = []
    }
  },
  { immediate: true }
)
</script>

<style lang="scss">
.elv-journals-group-container {
  height: calc(100vh - 178px);
  width: 100%;
  box-sizing: border-box;
  padding-bottom: 19px;

  .elv-journals-group-scrollbar {
    height: calc(100% - 42px);

    .el-scrollbar__wrap {
      width: 100%;
      box-sizing: border-box;
      padding-right: 30px;
      padding-left: 20px;
    }
  }

  .elv-journals-group-collapse.ant-collapse {
    margin-bottom: 8px;

    &:last-of-type {
      margin-bottom: 0;
    }

    .elv-journals-group-collapse-item {
      padding: 14px 12px;

      .ant-collapse-header {
        padding: 0;
        position: relative;

        .ant-collapse-expand-icon {
          position: absolute;
          right: 0;
          bottom: -5px;
          width: 60px;
          justify-content: flex-end;

          svg {
            transition: transform 0.3s;
          }
        }

        .ant-collapse-header-text {
          display: flex;
          flex-direction: column;
        }

        .elv-journals-group-collapse-item__header {
          width: 100%;
          padding-bottom: 10px;
          border-bottom: 1px solid #edf0f3;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        .elv-journals-group-collapse-item__header-title {
          h5 {
            color: #0e0f11;
            font-family: 'Plus Jakarta Sans';
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin-bottom: 6px;
          }

          > div {
            display: flex;
            align-items: center;

            p {
              color: #636b75;
              font-family: 'Barlow';
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              margin-right: 16px;
            }

            img {
              width: 14px;
              height: 14px;
              display: block;
              border-radius: 50%;
              margin-right: 6px;
            }

            span {
              color: #6b7177;
              font-family: 'Barlow';
              font-size: 11px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }
        }

        .elv-journals-group-collapse-item__header-amount {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          height: 31px;
          min-width: 316px;
          gap: 16px;
        }

        .elv-journals-group-collapse-item__header-amount-item {
          width: 150px;
          height: 100%;
          display: flex;
          margin-right: 16px;

          img {
            width: 14px;
            height: 14px;
            display: block;
            border-radius: 50%;
          }

          .elv-journals-group-collapse-item__header-amount__usd {
            display: flex;
            flex-direction: column;
            margin-left: 4px;

            span {
              color: #0e0f11;
              font-family: 'Barlow';
              font-size: 13px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              margin-bottom: 4px;
            }

            p {
              color: #838d95;
              font-family: 'Barlow';
              font-size: 11px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              margin-left: 2px;
            }

            .elv-journals-group-collapse-item__header-amount__currency-value {
              display: flex;
              align-items: center;
            }

            .elv-journals-group-collapse-item__header-amount__currency-list {
              display: flex;
              align-items: center;
              gap: 4px;

              div {
                display: flex;
                height: 12px;
                padding: 0px 2px;
                box-sizing: border-box;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                border-radius: 20px;
                background: #edf0f3;
                color: #636b75;
                font-family: 'Barlow';
                font-size: 8px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }
            }
          }

          &:last-of-type {
            margin-right: 0;
          }
        }

        .elv-journals-group-collapse-item__info {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          box-sizing: border-box;
          padding-top: 10px;
          padding-right: 16px;

          .elv-journals-group-collapse-item__info-total {
            color: #636b75;
            font-family: 'Plus Jakarta Sans';
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            display: flex;
            align-items: center;
            text-transform: lowercase;

            span {
              font-family: 'Barlow';
              font-weight: 500;
              margin-right: 4px;
            }
          }

          .elv-journals-group-collapse-item__info-list {
            display: flex;
            align-items: center;

            div {
              display: flex;
              align-items: center;

              &:last-of-type p {
                margin-right: 0;
              }
            }

            p {
              display: flex;
              align-items: center;
              color: #838d95;
              font-family: 'Plus Jakarta Sans';
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              margin-right: 10px;

              span {
                color: #636b75;
                font-family: 'Barlow';
                font-weight: 500;
                margin-left: 6px;
              }
            }
          }
        }
      }

      .ant-collapse-content {
        border-top: 0px;
        background-color: #f9fafb;
      }

      .ant-collapse-content-box {
        padding: 0;
        padding-top: 10px;
      }
    }
  }

  .elv-journals-group-empty-content {
    margin-top: 154px;

    .elv-result-description {
      margin-top: 12px;

      p {
        color: #000;
        font-family: 'Plus Jakarta Sans';
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;

        span {
          color: #2f63eb;
          cursor: pointer;
          text-decoration-line: underline;
          text-transform: lowercase;
          margin-left: 8px;

          &.is-disabled {
            color: #c4c9d1;
            cursor: not-allowed;
          }
        }
      }
    }
  }
}
</style>
